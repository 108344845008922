import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarMasMoneyWallet() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarMasMoneyWalletTrack = () => {
    track({
      event_type: 'Sidebar Más Money Wallet',
      event_data: { screen: 'Bank accounts' },
    });
    // amplitude.track('Sidebar Más Money Wallet');
  };
  return {
    sidebarMasMoneyWalletTrack,
  };
}
