import Page from '@/classes/contentful/Page';
import useContentfulPageLocaleById from '@/hooks/useContentfulPageLocaleById';
import useLanguage from '@/hooks/useLanguage';
import useAuthProfile from '../useAuthProfile';

export default function useContentfulDrawer() {
  const { currentLocale } = useLanguage();

  const { authProfile } = useAuthProfile();

  const { page } = useContentfulPageLocaleById(
    'Drawer',
    currentLocale,
    authProfile?.isLoggedIn === true
  );

  return page ? new Page(page) : null;
}
