import Typography from '@/components/atoms/Typography';
import React from 'react';
import classNames from 'classnames';
import Button from '@/components/atoms/Button';
import useLanguage from '@/hooks/useLanguage';

export interface LanguageSelectorProps {
  onClose?: () => void;
  visible?: boolean;
  type: 'header' | 'drawer';
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  onClose,
  visible,
  type = 'drawer',
}) => {
  const { currentLocale, changeLocale } = useLanguage();
  if (!visible) {
    return null;
  }

  if (type === 'drawer') {
    return (
      <div className="mt-[1em] flex cursor-pointer flex-col items-center justify-center">
        <div className="flex flex-row">
          <Button variant="link" size="sm" onClick={() => changeLocale('en-US', onClose)} className="text-sumawealth-grey-80 no-underline">
            <Typography
              variant="heading3"
              className={classNames({
                'font-medium': true,
                'font-bold': currentLocale === 'en-US',
                'text-white': currentLocale === 'en-US',
              })}
            >
              EN
            </Typography>
          </Button>
          <Typography variant="heading2" className="mx-2">
            /
          </Typography>
          <Button variant="link" size="sm" onClick={() => changeLocale('es', onClose)} className="text-sumawealth-grey-80 no-underline">
            <Typography
              variant="heading3"
              className={classNames({
                'font-medium': true,
                'font-bold': currentLocale === 'es',
                'text-white': currentLocale === 'es',
              })}
            >
              ES
            </Typography>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="absolute right-8 flex cursor-pointer flex-col items-center justify-center xl:top-6">
      <div className="flex flex-row">
        <Button variant="link" size="sm" onClick={() => changeLocale('en-US', onClose)} className="text-sumawealth-grey-80 no-underline">
          <Typography
            variant="heading3"
            className={classNames({
              'font-medium': true,
              'font-bold': currentLocale === 'en-US',
              'text-white': currentLocale === 'en-US',
            })}
          >
            EN
          </Typography>
        </Button>
        <Typography variant="heading3" className="mx-2">
          /
        </Typography>
        <Button variant="link" size="sm" onClick={() => changeLocale('es', onClose)} className="text-sumawealth-grey-80 no-underline">
          <Typography
            variant="heading3"
            className={classNames({
              'font-medium': true,
              'font-bold': currentLocale === 'es',
              'text-white': currentLocale === 'es',
            })}
          >
            ES
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default LanguageSelector;
