// import * as amplitude from '@amplitude/analytics-browser';
import { trpc } from '@/utils/trpc';

export function useSidebarGrowYourSavings() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarGrowYourSavingsTrack = () => {
    track({
      event_type: 'Sidebar Grow Your Savings',
      event_data: { screen: 'grown-your-saving' },
    });
    // amplitude.track('Sidebar Grow Your Savings');
  };
  return {
    sidebarGrowYourSavingsTrack,
  };
}
