import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import Drawer from '@/components/atoms/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
// import Typography from '@/components/atoms/Typography';
import VerticalLayout from '@/components/layouts/VerticalLayout';
import useLogout from '@/hooks/useLogout';
import useUserProfile from '@/hooks/useUserProfile';
import DrawerLayout from '@/components/layouts/DrawerLayout';
import { useRouter } from 'next/router';
import useCollapse from 'react-collapsed';
import classNames from 'classnames';
import { useSidebarMasMoneyJourney } from '@/hooks/amplitude/useSidebarMasMoneyJourney';
import { useSidebarSumarversity } from '@/hooks/amplitude/useSidebarSumarversity';
import { useSidebarHomeRentVsBuy } from '@/hooks/amplitude/useSidebarHomeRentVsBuy';
import { useSidebarGrowYourSavings } from '@/hooks/amplitude/useSidebarGrowYourSavings';
import { useSidebarPayYourCreditCard } from '@/hooks/amplitude/useSidebarPayYourCreditCard';
// import { useSidebarMoneyCoach } from '@/hooks/amplitude/useSidebarMoneyCoach';
// import { useSidebarSumaPlans } from '@/hooks/amplitude/useSidebarSumaPlans';
// import { useSidebarNotification } from '@/hooks/amplitude/useSidebarNotifications';
import { useSidebarPersonalInformation } from '@/hooks/amplitude/useSidebarPeronalInformation';
import { useSidebarOnLogout } from '@/hooks/amplitude/useSidebarOnLogout';
import { useSidebarMasMoneyWallet } from '@/hooks/amplitude/useSidebarMasMoneyWallet';
// import { useSubscriptionsFlow } from '@/hooks/amplitude/useSubscriptionsFlow';
import { trialExpiredIn } from '@/utils/date';
// import useSubscription from '@/hooks/useSubscription';
import Typography from '@/components/atoms/@next/Typography';
import LanguageSelector from '../LanguageSelector';
import useContentfulDrawer from '@/hooks/contenful/useContentfulDrawer';

export interface AppDrawerProps {
  isOpen: boolean;
  onClose: AnyFunction;
}

const AppDrawer: React.FC<AppDrawerProps> = ({ isOpen, onClose }) => {
  const { userProfile } = useUserProfile();
  const { logout } = useLogout();
  const router = useRouter();
  router.events?.on('routeChangeStart', onClose);

  const page = useContentfulDrawer();

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerLayout>
        <AppDrawerContents
          onClose={onClose}
          userProfile={userProfile}
          logout={() => {
            onClose();
            // setTimeout(() => {
            logout({ redirect: true });
            // }, 250);
          }}
          contentful={{
            hello: page?.items.hello.text,
            yourCurrentPlan: page?.items.yourCurrentPlan.text,
            days: page?.items.days.text,
            yourFreeTrial: page?.items.yourFreeTrial.text,
            myProfile: page?.items.myProfile.text,
            personalnfo: page?.items.personalnfo.text,
            bankAccounts: page?.items.bankAccounts.text,
            transferFunds: page?.items.transferFunds.text,
            accountOverview: page?.items.accountOverview.text,
            yourMoneyTeam: page?.items.yourMoneyTeam.text,
            getSmarter: page?.items.getSmarter.text,
            moreMoneyTools: page?.items.moreMoneyTools.text,
            rentOrBuy: page?.items.rentOrBuy.text,
            growYourSavings: page?.items.growYourSavings.text,
            payYourCreditCard: page?.items.payYourCreditCard.text,
            faqs: page?.items.faqs.text,
            logout: page?.items.logout.text,
            sumaMemberships: page?.items.sumaMemberships.text,
          }}
        />
      </DrawerLayout>
    </Drawer>
  );
};

export const AppDrawerContents = ({
  userProfile,
  logout,
  onClose,
  contentful,
}: {
  userProfile?: UserProfile;
  logout: () => void;
  onClose?: () => void;
  contentful: {
    hello: string;
    yourCurrentPlan: string;
    days: string;
    yourFreeTrial: string;
    myProfile: string;
    personalnfo: string;
    bankAccounts: string;
    transferFunds: string;
    accountOverview: string;
    yourMoneyTeam: string;
    getSmarter: string;
    moreMoneyTools: string;
    rentOrBuy: string;
    growYourSavings: string;
    payYourCreditCard: string;
    faqs: string;
    sumaMemberships: string;
    logout: string;
  };
}) => {
  // const { hasSubscription, subscription, subscriptionType } = useSubscription();
  const sumaPlansUrls = `${process.env.NEXT_PUBLIC_LINKR_SUMA_URL}/paywall`;
  const { sidebarMasMoneyJourneyTrack } = useSidebarMasMoneyJourney();
  const { sidebarSumarversityTrack } = useSidebarSumarversity();
  const { sidebarHomeRentVsBuyTrack } = useSidebarHomeRentVsBuy();
  const { sidebarGrowYourSavingsTrack } = useSidebarGrowYourSavings();
  const { sidebarPayYourCreditCardTrack } = useSidebarPayYourCreditCard();
  // const { sidebarMoneyCoachTrack } = useSidebarMoneyCoach();
  // const { sidebarSumaPlansTrack } = useSidebarSumaPlans();
  // const { sidebarNotificationTrack } = useSidebarNotification();
  const { sidebarPersonalInformationTrack } = useSidebarPersonalInformation();
  const { sidebarMasMoneyWalletTrack } = useSidebarMasMoneyWallet();
  const { sidebarOnLogoutTrack } = useSidebarOnLogout();
  // const amplitudeTrack = useSubscriptionsFlow();
  // const moneyCoachTrack = () => {
  //   amplitudeTrack.userClicksMoneyCoachButtonTrack();
  // };
  // const sumaPlansTrack = () => {
  //   amplitudeTrack.userClicksSumaPlansButtonTrack();
  // };

  const onLogout = () => {
    sidebarOnLogoutTrack();
    logout();
  };

  return (
    <VerticalLayout className="relative w-full p-4 md:p-8">
      <div className="flex items-center gap-4">
        <div className="flex flex-col">
          <Typography
            variant="heading"
            size="24px"
            className="!font-normal text-sumawealth-yellow-80"
          >
            {contentful.hello}
          </Typography>
          <Typography
            variant="heading"
            size="48px"
            className="pt-2 !font-normal text-sumawealth-neutral-0"
          >
            {userProfile?.fullName || <>&nbsp;</>}
          </Typography>

          {/* {hasSubscription &&
          subscription &&
          subscriptionType !== 'MONEY_COACH_ONE_TIME' &&
          subscriptionType !== 'MONEY_COACH_SUBSCRIPTION' ? (
            <Typography variant="body" size="14px" className="mt-2 text-sumawealth-yellow-80">
              {contentful.yourCurrentPlan}{' '}
              <span className="font-bold">
                {subscription?.price?.product.subscriptionPlan.replace('_', ' ')}
              </span>
            </Typography>
          ) : null} */}
          {userProfile?.trial.isActive && !userProfile.trial.isExpired ? (
            <Typography variant="body" size="14px" className="mt-2 text-sumawealth-yellow-80">
              {contentful.yourFreeTrial}{' '}
              <span className="font-bold">
                {trialExpiredIn(userProfile.trial.endDate)} {contentful.days}
              </span>
            </Typography>
          ) : null}
        </div>
      </div>
      <nav className="flex flex-col divide-y divide-sumawealth-grey-100 border-y border-sumawealth-grey-100 ">
        <AppDrawerCollapseNavLink title={contentful.myProfile} icon={faChevronRight}>
          <AppDrawerNavLink href="/account" onClick={sidebarPersonalInformationTrack}>
            {contentful.personalnfo}
          </AppDrawerNavLink>

          <AppDrawerNavLink href="/mx-accounts" onClick={sidebarMasMoneyWalletTrack}>
            {contentful.bankAccounts}
          </AppDrawerNavLink>
          

          <AppDrawerNavLink href="https://docs.google.com/forms/d/e/1FAIpQLSfIkkDzhvKyXwDtSps9JHWPMqelGKMUSXRW4kiR27RaFozsKw/viewform">
            {contentful.transferFunds}
          </AppDrawerNavLink>
          {/* <AppDrawerNavLink href="/saving-goals">Saving goals</AppDrawerNavLink> */}
        </AppDrawerCollapseNavLink>

        <AppDrawerNavLink href="/mas-money-journey" onClick={sidebarMasMoneyJourneyTrack}>
          {contentful?.accountOverview}
        </AppDrawerNavLink>

        {/* <AppDrawerNavLink href="/my-money-coach" onClick={moneyCoachTrack}>
          Your money team
        </AppDrawerNavLink> */}
        {/* <AppDrawerNavLink href="/my-money-coach" onClick={moneyCoachTrack}>
          {contentful?.yourMoneyTeam}
        </AppDrawerNavLink> */}

        <AppDrawerNavLink href="/sumaversity" onClick={sidebarSumarversityTrack}>
          {contentful?.getSmarter}
        </AppDrawerNavLink>

        <AppDrawerCollapseNavLink title={contentful.moreMoneyTools} icon={faChevronRight}>
          <AppDrawerNavLink href="/toolkit/rent-by-home" onClick={sidebarHomeRentVsBuyTrack}>
            {contentful?.rentOrBuy}
          </AppDrawerNavLink>

          <AppDrawerNavLink
            href="/toolkit/grown-your-savings"
            onClick={sidebarGrowYourSavingsTrack}
          >
            {contentful?.growYourSavings}
          </AppDrawerNavLink>

          <AppDrawerNavLink
            href="/toolkit/pay-your-credit-card"
            onClick={sidebarPayYourCreditCardTrack}
          >
            {contentful?.payYourCreditCard}
          </AppDrawerNavLink>
        </AppDrawerCollapseNavLink>

        <AppDrawerNavLink href="/faq" onClick={sidebarMasMoneyJourneyTrack}>
          {contentful?.faqs}
        </AppDrawerNavLink>

        <AppDrawerNavLink href={sumaPlansUrls}>{contentful?.sumaMemberships}</AppDrawerNavLink>

        <AppDrawerNavLinkContents onClick={onLogout}>{contentful?.logout}</AppDrawerNavLinkContents>
      </nav>
      <LanguageSelector type="drawer" visible={true} onClose={onClose} />
    </VerticalLayout>
  );
};
export const AppDrawerCollapseNavLink: React.FC<{
  icon: IconProp;
  title: string;
}> = ({ title, children, icon }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({});

  const iconClassNames = classNames({
    'rotate-90': isExpanded,
  });

  return (
    <div>
      <AppDrawerNavLinkContents icon={icon} iconClassNames={iconClassNames} {...getToggleProps()}>
        {title}
      </AppDrawerNavLinkContents>
      <div className="pl-6" {...getCollapseProps()}>
        {children}
      </div>
    </div>
  );
};

export const AppDrawerNavLink: React.FC<{
  href: string;
  icon?: IconProp;
  iconClassNames?: string;
  onClick?: AnyFunction;
}> = ({ href, children, iconClassNames, icon, onClick }) => {
  // Verifica si la URL es la específica de "Transfer funds"
  const shouldOpenInNewTab =
    href ===
    'https://docs.google.com/forms/d/e/1FAIpQLSfIkkDzhvKyXwDtSps9JHWPMqelGKMUSXRW4kiR27RaFozsKw/viewform';

  return (
    <a
      href={href}
      // Añade el atributo target solo si es el enlace "Transfer funds"
      target={shouldOpenInNewTab ? '_blank' : undefined}
      rel={shouldOpenInNewTab ? 'noopener noreferrer' : undefined}
      onClick={onClick}
    >
      <AppDrawerNavLinkContents icon={icon} iconClassNames={iconClassNames}>
        {children ? (
          children
        ) : (
          <div className="mb-2 h-4 w-40 animate-pulse rounded bg-sumawealth-grey-40"></div>
        )}
      </AppDrawerNavLinkContents>
    </a>
  );
};

export const AppDrawerNavLinkContents: React.FC<{
  icon?: IconProp;
  iconClassNames?: string;
  onClick?: AnyFunction;
}> = ({ icon, children, iconClassNames, onClick }) => (
  <div
    className="flex cursor-pointer items-center justify-between px-1 py-3 transition duration-300 hover:bg-sumawealth-sky-blue-80"
    onClick={onClick}
  >
    <Typography variant="heading" size="24px" className="!font-normal text-sumawealth-neutral-0">
      {children}
    </Typography>

    {icon && (
      <FontAwesomeIcon
        icon={icon}
        className={classNames('transition duration-500', iconClassNames)}
      />
    )}
  </div>
);

export default AppDrawer;
