import { format, differenceInDays } from 'date-fns';

export const toAWSDate = (date: Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const fromAWSDate = (awsDate: string): Date => {
  return new Date(awsDate);
};

export const toDateInputFormat = (date: Date): string => {
  return format(dateToUTC(date), 'MM/dd/yyyy');
};

export const fromDateInputFormat = (date: string): Date => {
  return dateToUTC(new Date(date));
};

export const dateToUTC = (date: Date): Date => {
  const now = new Date();
  return new Date(date.getTime() + now.getTimezoneOffset() * 60000);
};

export const trialExpiredIn = (endDate: string) => {
  return differenceInDays(new Date(endDate), new Date(format(new Date(), 'yyyy-MM-dd')));
};
