import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebar() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebartrack = () => {
    track({
      event_type: 'Sidebar',
      event_data: { screen: 'What’s Up saver' },
    });
    // amplitude.track('Sidebar');
  };
  return {
    sidebartrack,
  };
}
