import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarPayYourCreditCard() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarPayYourCreditCardTrack = () => {
    track({
      event_type: 'Sidebar Pay Your Credit Card',
      event_data: { screen: 'Pay your credit card' },
    });
    // amplitude.track('Sidebar Pay Your Credit Card');
  };
  return {
    sidebarPayYourCreditCardTrack,
  };
}
