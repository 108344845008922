import { trpc } from '@/utils/trpc';
import { useEffect } from 'react';
import { createStateContext } from 'react-use';
import { useLocalStorage } from 'react-use';

interface SvgRoadTourProviderProps {
  children: React.ReactNode;
}

export const initialValues = {
  started: false,
  finished: false,
  sequence: 0,
  hasAccount: false,
  modalShownAccount: false,
  enabled: true,
};

const [useSharedState, SharedStateProvider] = createStateContext(initialValues);

const SvgRoadTourProvider: React.FC<SvgRoadTourProviderProps> = ({ children }) => {
  const { queryClient } = trpc.useContext();

  const [value, setValue] = useLocalStorage(`suma-road-tour-state`, {
    ...initialValues,
  });

  const userProfile: UserProfile | undefined = queryClient.getQueryData(['user-profile.get']);

  useEffect(() => {
    if (
      userProfile &&
      userProfile.trial &&
      userProfile.trial.isActive &&
      !userProfile.trial.isExpired &&
      !userProfile.hasAnyMembers
    ) {
      setValue({
        ...initialValues,
        finished: true,
      });
    }
  }, [userProfile, setValue]);

  return <SharedStateProvider initialValue={value}>{children}</SharedStateProvider>;
};

export const SvgRoadTourContext = {
  SvgRoadTourProvider,
  useSharedState: useSharedState,
  initialValues: initialValues,
};
