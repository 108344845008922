import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarOnLogout() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarOnLogoutTrack = () => {
    track({
      event_type: 'Sidebar OnLogout',
      event_data: { screen: 'Logout' },
    });
    // amplitude.track('Sidebar OnLogout');
  };
  return {
    sidebarOnLogoutTrack,
  };
}
