import Script from 'next/script';
import { AppDrawerProvider } from '@/context/AppDrawerContext';
// import { AppSearchProvider } from '@/context/AppSearchContext';
import Footer from '@/components/organisms/Footer';
import React from 'react';
// import { WantsProvider } from '@/context/WantsContext';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { SvgRoadTourContext } from '@/context/SvgRoadTourContext';

const AppHeader = dynamic<any>(() =>
  import('@/components/organisms/Header/Header').then((mod) => mod.AppHeader)
);

interface AppTemplateProps {
  showHeader?: boolean;
  showFooter?: boolean;
  children: React.ReactNode;
  className?: string;
  containerFluid?: boolean;
  customPadding?: string;
}

export const AppTemplate: React.FC<AppTemplateProps> = (props) => {
  return (
    <>
      <Script
        strategy="afterInteractive"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_TAG_MANAGER_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_TAG_MANAGER_ID}');
          `,
        }}
        async
      />
      {/* <AppSearchProvider> */}
      <AppDrawerProvider>
        <SvgRoadTourContext.SvgRoadTourProvider>
          {/* <WantsProvider> */}
          {/* Can be used by portals to display content outside the limits
            of AppTemplate container. */}
          <div id="app-template-top-escape-hatch" />
          <AppTemplateContents {...props} />
          <div id="app-template-bottom-escape-hatch" />

          {/* </WantsProvider> */}
        </SvgRoadTourContext.SvgRoadTourProvider>
      </AppDrawerProvider>
      {/* </AppSearchProvider> */}
    </>
  );
};

const AppTemplateContents: React.FC<AppTemplateProps> = ({
  showHeader = true,
  showFooter = true,
  containerFluid = false,
  customPadding = 'py-9',
  className,
  children,
}) => (
  <>
    <div className="flex min-h-screen flex-col">
      <div className="flex-shrink-0">{showHeader && <AppHeader />}</div>
      <main
        className={classNames(
          'flex h-full flex-grow break-words',
          containerFluid ? 'container-fluid' : 'container',
          customPadding,
          className
        )}
      >
        {children}
      </main>

      {/* Can be used by portals to display content outside the limits of the app
      template contents and above the footer */}
      <div id="app-template-contents-bottom-escape-hatch" />
    </div>
    {showFooter && <Footer></Footer>}
  </>
);
