import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarSumarversity() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarSumarversityTrack = () => {
    track({
      event_type: 'Sidebar Sumarvesity',
      event_data: { screen: 'Get smarter' },
    });
    // amplitude.track('Sidebar Sumarvesity');
  };
  return {
    sidebarSumarversityTrack,
  };
}
