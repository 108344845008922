import Page from '@/classes/contentful/Page';
import useContentfulPageLocaleById from '@/hooks/useContentfulPageLocaleById';
import useLanguage from '@/hooks/useLanguage';

export default function useContentfulFooter() {
  const { currentLocale } = useLanguage();

  const { page } = useContentfulPageLocaleById('Footer', currentLocale);

  return page ? new Page(page) : null;
}
