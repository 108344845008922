import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarPersonalInformation() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarPersonalInformationTrack = () => {
    track({
      event_type: 'Sidebar Personal Information',
      event_data: { screen: 'Personal info' },
    });
    // amplitude.track('Sidebar Personal Information');
  };
  return {
    sidebarPersonalInformationTrack,
  };
}
