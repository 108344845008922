import React, { useState } from 'react';

import AppDrawer from '@/components/molecules/AppDrawer';
import noop from 'lodash/noop';
import { useSidebar } from '@/hooks/amplitude/useSidebar';

export const AppDrawerContext = React.createContext({
  open: noop,
});

export const AppDrawerProvider: React.FC = ({ children }) => {
  const [isOpen, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const { sidebartrack } = useSidebar();

  const onOpen = () => {
    sidebartrack();
    setOpen(true);
  };

  return (
    <AppDrawerContext.Provider
      value={{
        open: onOpen,
      }}
    >
      {children}

      <AppDrawer isOpen={isOpen} onClose={onClose} />
    </AppDrawerContext.Provider>
  );
};
