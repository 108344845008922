import { trpc } from '@/utils/trpc';
// import * as amplitude from '@amplitude/analytics-browser';

export function useSidebarHomeRentVsBuy() {
  const { mutateAsync: track } = trpc.useMutation('event.track');
  const sidebarHomeRentVsBuyTrack = () => {
    track({
      event_type: 'Sidebar Home Rent vs Buy',
      event_data: { screen: 'Rent or buy' },
    });
    // amplitude.track('Sidebar Home Rent vs Buy');
  };
  return {
    sidebarHomeRentVsBuyTrack,
  };
}
